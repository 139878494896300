// import 'core-js/es6/array'; // Required by IE11 for detect-browser
// import 'core-js/es6/promise'; // Required by IE11 for detect-browser
//const { detect } = require('detect-browser');

const loginAndRun = async () => {
   
    // Dynamically import polyfills only as needed
    // const browserName = detect().name;
    // if(browserName === 'ie') {
    //     await import('./polyfill.ie11');
    // } else if(browserName === 'edge') {
    //     await import('./polyfill.edge');
    // }

    // Side effect of this import is the instantiation of the MSAL UserAgentApplication
    const { logIn, isLoggedIn } = await import('./services/auth/msalUtils');

    // Unlike in adal.js, where tokens had to be explicitly ingested by
    // the developer using AuthenticationContext.handleWindowCallback,
    // instantiating the MSAL UserAgentApplication should be sufficient
    // to ingest tokens in the URL hash
    //
    // We simply need to detect the case when we're running inside an iFrame,
    // and prevent further execution of the application
    //
    // See https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/FAQs#i-dont-understand-the-redirect-flow-how-does-the-handleredirectcallback-function-works for more
    if(window !== window.parent) return;

    if(!isLoggedIn()) {
        await logIn();
    }

    const [
        React,
        ReactDom,
        { App }
    ] = await Promise.all([
        import('react'),
        import('react-dom'),
        import('./App')
    ]);

    // Remove the loadError div
    const loadErrorDiv = document.getElementById("loadError");
    if (loadErrorDiv) {
        loadErrorDiv.parentNode.removeChild(loadErrorDiv);
    }

    ReactDom.render(
        <App/>,
        document.getElementById("root"));
}

loginAndRun();